import { ref } from 'vue';
import { useRequestHeaders } from '#app';
export const useDeviceType = () => {
  let UA = "";
  if (process.client) {
    UA = navigator.userAgent;
  } else {
    const headers = useRequestHeaders();
    UA = headers["user-agent"] || "";
  }

  const type = ref("pc");
  // 通过 UA 来判断设备类型是 pc 还是 mobile
  if (/(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(UA)) {
    type.value = "mobile";
  }

  return type;
};
